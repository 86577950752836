<template>
  <div>
    <div class="tile">
      <logsTable type="registration" :columns="columns"></logsTable>
    </div>
  </div>
</template>
<script>
import logsTable from "../components/logs";
export default {
  data() {
    return {
      columns: [
        {
          label: "Name",
          field: "request.fname",
        },
        {
          label: "Surname",
          field: "request.lname",
        },
        {
          label: "Email",
          field: "request.email",
        },
        {
          label: "User",
          field: ({ response }) => {
            return response.data.length ? response.data[0].user : "-----";
          },
        },
        {
          label: "Method",
          field: "request.method",
        },
        {
          label: "License",
          field: ({ request }) => {
            return request.license.toUpperCase();
          },
        },
        {
          label: "Ref Link",
          field: this.splitRefLink,
        },
        {
          label: "Language",
          field: "request.language",
        },
        {
          label: "Response",
          field: "response.info.message",
        },
        {
          label: "Date",
          field: "created",
          formatFn: (created) => {
            return new Date(created).toLocaleString("en-GB", {
              timeZone: "UTC",
            });
          },
        },
      ],
    };
  },
  components: {
    logsTable,
  },
  methods: {
    splitRefLink({ request: { httpref, fname } }) {
      if (httpref?.includes("demo=1")) {
        return "Demo";
      } else if (httpref?.includes("countryPopup")) {
        return "Country Popup";
      } else if (httpref?.includes("sidc=")) {
        return "SIDC";
      } else if (httpref?.includes("sidi=")) {
        return "SIDI";
      } else if (fname?.includes("test")) {
        return "Test";
      } else {
        return "Live";
      }
    },
  },
};
</script>
