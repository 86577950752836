<template>
  <div>
    <div class="col-12">
      <label for="picker" class="form-label">Select Filter:</label>
      <select
        class="form-select mt-2 mb-3"
        aria-label="license"
        v-model="license"
      >
        <option value="eu">EU</option>
        <option value="global">GLOBAL</option>
        <option selected value="all">ALL</option>
      </select>
      <select class="form-select mb-4" aria-label="status" v-model="status">
        <option selected value="true">Success</option>
        <option value="false">Failed</option>
      </select>
    </div>
    <vue-good-table
      :columns="columns"
      :rows="rows"
      @on-page-change="onPageChange"
      @on-per-page-change="onPerPageChange"
      @on-selected-rows-change="selectRow"
      :pagination-options="{
        enabled: true,
      }"
      :search-options="{
        enabled: true,
        placeholder: 'Search',
      }"
      :select-options="{ enabled: true, selectOnCheckboxOnly: true }"
      :totalRows="totalRecords"
    >
      <div slot="selected-row-actions">
        <button type="button" class="btn btn-danger" @click.prevent="deleteLog">
          Delete
        </button>
      </div>
      <template slot="table-row" slot-scope="props">
        {{ props.formattedRow[props.column.field] }}
      </template>
      <div slot="emptystate">
        <div v-if="isLoading" class="d-flex justify-content-center">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <span v-else>No data</span>
      </div>
      <div slot="table-actions-bottom" class="p-2">
        <span
          >Total: <b>{{ totalRecords }}</b> successful logins
        </span>
      </div>
    </vue-good-table>
  </div>
</template>
<script>
import { mutations } from "../store";
export default {
  props: {
    type: {
      type: String,
      default: "",
    },
    columns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      rows: [],
      status: true,
      license: "all",
      totalRecords: 0,
      isLoading: false,
      selectedRowsList: [],
    };
  },
  created() {
    this.getLogs();
  },
  watch: {
    license() {
      this.getLogs();
    },
    status() {
      this.getLogs();
    },
  },
  methods: {
    getLogs(page = 1, limit = 10) {
      this.rows = [];
      this.isLoading = true;
      let limitPerPage = limit <= -1 ? this.totalRecords : limit;
      this.$axios({
        url: `/logs?type=${this.type}&license=${this.license}&success=${this.status}&page=${page}&limit=${limitPerPage}`,
      })
        .then(({ data: { logsList, pageCount } }) => {
          this.isLoading = false;
          this.rows = logsList;
          this.totalRecords = pageCount;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onPageChange({ currentPage, currentPerPage }) {
      this.getLogs(currentPage, currentPerPage);
    },
    onPerPageChange({ currentPage, currentPerPage }) {
      this.getLogs(currentPage, currentPerPage);
    },
    selectRow({ selectedRows }) {
      this.selectedRowsList = selectedRows;
    },
    deleteLog() {
      this.$axios
        .delete("/logs", {
          data: { itemsToDelete: this.selectedRowsList },
        })
        .then(({ data: { success, message } }) => {
          this.rows = this.rows.filter(
            (a) => !this.selectedRowsList.map((a) => a._id).includes(a._id)
          );
          mutations.showToast({ success, message });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
